const data = [
  {
    id: 1,
    title: "ACM ICPC 2023 Regionalist",
    desc: "Selected for the upcoming ACM ICPC regional-level competitive programming competition. This event brings together talented programmers from various institutions to solve algorithmic problems and compete at a regional level.",
  },
  {
    id: 2,
    title: "Bugs4Ever - SIES Intercollege Coding Competititon",
    rank: "2nd Rank",
    link: "https://www.linkedin.com/posts/manas-telavane-b3356a199_codechef-cp-dsa-activity-6982711420186419200-SuNs?utm_source=share&utm_medium=member_desktop",
    desc: "This was a coding contest based on algorithms and data structures, and aims to encourage participants to apply their knowledge towards problem solving. It consisted of two rounds with 70+ particpants.",
  },
  {
    id: 3,
    title: "Completed HacktoberFest",
    rank: "4 PRs",
    link: "https://www.linkedin.com/posts/manas-telavane-b3356a199_opensource-hacktoberfest2022-hacktoberfest-activity-6990560403491577856-U-ce?utm_source=share&utm_medium=member_desktop",
    desc: "Hacktoberfest: a month-long celebration of open-source projects.Successfully merged 4 pull requests from which 1 PR was merged to styles.monday.com which is a popular React component library with 1 Lakh+ lines of code. ",
  },
  {
    id: 4,
    title: "Codechef Contest",
    rank: "4⭐ (highest rating)",
    desc: "Attained a 4-star rating on Codechef, denoting proficiency in competitive programming and problem-solving. Consistently competed in coding challenges to learn different coding paradigm.",
  },
];

export default data;
