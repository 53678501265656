import {HiOutlineMail} from 'react-icons/hi'
import {FaWhatsapp} from 'react-icons/fa'
import { AiFillGithub, AiFillLinkedin, AiOutlineTwitter } from 'react-icons/ai'

const data = [
    {id: 1, icon: <HiOutlineMail/>, link: 'mailto:manastelavane999@gmail.com'},
    // {id: 2, icon: <RiMessengerLine/>, link: 'https://www.messenger.com/t/100087217880007'},
    {id: 2, icon: <FaWhatsapp/>, link: 'https://wa.me/+917397932567'},
    {id: 3, link: 'https://www.linkedin.com/in/manas-telavane-b3356a199/', icon: <AiFillLinkedin/>},
    {id: 4, link: 'https://github.com/manastelavane', icon: <AiFillGithub/>},
    {id: 5, link: 'https://twitter.com/TelavaneManas', icon: <AiOutlineTwitter/>},
]


// alternative whatsApp link
// https://wa.me/yournumber
// https://wa.me/7397932567
// https://api.whatsapp.com/send/?phone=%2B233557097546


export default data