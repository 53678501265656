import CV from "../../Manas_Telavane_Resume_v1.2.pdf";
import { HiDownload } from "react-icons/hi";
import Card from "../../components/Card";
import "./about.css";
import { SiCodechef } from "react-icons/si";
import { TbLetterT } from "react-icons/tb";

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
      <div className="container about__container">
        <div className="about__right">
          <h2 style={{ textAlign: "center" }}>About Me</h2>

          <h5>
            I am an undergraduate final year student pursuing my Computer
            Engineering(9/10 CGPA) at Terna Engineering College, Mumbai.
          </h5>
          <br />
          <p>
            I am a web developer enthusiast with a strong passion for creating
            intuitive and user-friendly websites. I have always been fascinated
            by the power of technology to connect people and improve their daily
            lives, and I am excited to be a part of the development process. In
            my free time, I enjoy staying up-to-date on the latest web
            development trends and techniques, and I am always looking for ways
            to improve my skills and expand my knowledge.
          </p>
          <p>
            I am also an avid competitive coder, and I love the challenge of
            solving complex problems under pressure. I am driven by the thrill
            of finding creative solutions to complex problems and the
            satisfaction of seeing my work come to life.{" "}
          </p>
        </div>
        <br />
        <div className="exper">
          <h2>Experience:</h2>
          <div className="container exper__container">
            <Card className="exper-in">
              <div className="exper__icon">
                <TbLetterT />
              </div>
              <div className="exper__details">
                <a
                  href="https://media.licdn.com/dms/image/C4D22AQH_k7Vr539Cmw/feedshare-shrink_800/0/1660976513488?e=1675900800&v=beta&t=UijaQX6JBJd_zrzGAfI6x0AmZYnosvWRGBsun8isFSQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4>
                    Techwondoe Limited: Full Stack Developer Intern -{" "}
                    <span style={{ fontWeight: "400" }}>6 months</span>
                  </h4>
                </a>
                <p>
                  Executed the development of a fully-proctored quiz web app,
                  leveraging React and NestJS to slash onboarding time by 50%
                  and notably enhance employee satisfaction. Contributed
                  significantly to deploying a NextJS dashboard tailored for a
                  credit-card company's needs. Additionally, crafted a
                  React-Native mobile app focused on tracking women's wellness,
                  integrating RTK for streamlined API queries
                </p>
              </div>
            </Card>
            <Card className="exper-in">
              <div className="exper__icon">
                <SiCodechef />
              </div>
              <div className="exper__details">
                <a
                  href="https://media.licdn.com/dms/image/C4D22AQH_k7Vr539Cmw/feedshare-shrink_800/0/1660976513488?e=1675900800&v=beta&t=UijaQX6JBJd_zrzGAfI6x0AmZYnosvWRGBsun8isFSQ"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h4>
                    Codechef: Doubt Solver -{" "}
                    <span style={{ fontWeight: "400" }}>3 months</span>
                  </h4>
                </a>
                <p>
                  Addressed over 450+ doubts in Competitive Programming and Data
                  Structures and Algorithms, demonstrating adeptness in
                  problem-solving. Provided support in debugging codes,
                  explaining problems, strategic hints, navigating through
                  solution approches and took 3 doubt solving sessions. These
                  experiences further developed my technical abilities and
                  strengthened my skills in explaining concepts effectively.
                </p>
              </div>
            </Card>
          </div>
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href={CV} download className="btn primary">
            Download Resume <HiDownload />
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
