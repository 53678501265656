import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";

export const links = [
  { id: 1, link: "#", title: "Home" },
  { id: 2, link: "#about", title: "About" },
  { id: 3, link: "#achievements", title: "Achievements" },
  { id: 4, link: "#portfolio", title: "Portfolio" },
  { id: 5, link: "#contact", title: "Contact" },
];

export const socials = [
  {
    id: 1,
    link: "https://www.linkedin.com/in/manas-telavane-b3356a199/",
    icon: <AiFillLinkedin />,
  },
  { id: 2, link: "https://github.com/manastelavane", icon: <AiFillGithub /> },
  {
    id: 3,
    link: "https://twitter.com/TelavaneManas",
    icon: <AiOutlineTwitter />,
  },
];
