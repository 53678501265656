import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { SiCodechef } from "react-icons/si";
import { SiLeetcode } from "react-icons/si";

const data = [
  {
    id: 1,
    link: "https://www.linkedin.com/in/manas-telavane-b3356a199/",
    icon: <AiFillLinkedin />,
  },
  { id: 2, link: "https://github.com/manastelavane", icon: <AiFillGithub /> },
  {
    id: 3,
    link: "https://twitter.com/TelavaneManas",
    icon: <AiOutlineTwitter />,
  },
  {
    id: 4,
    link: "https://www.codechef.com/users/manas2002",
    icon: <SiCodechef />,
  },
  {
    id: 5,
    link: "https://leetcode.com/manastelavane999/",
    icon: <SiLeetcode />,
  },
];

export default data;
