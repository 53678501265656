const data = [
  {
    id: 1,
    category: "MERN",
    image:
      "https://raw.githubusercontent.com/manastelavane/RecipeImages/main/MainRecipess.png",
    title: "Recipe Website (MERN stack)",
    desc: "Here, you will find a variety of delicious and easy-to-follow recipes for all kinds of dishes. Recommendation based on your searched Recipe. Chat Feature to help others and take help from community.Few best videos of recipe fetched from youtube.PWA for mobiles",
    demo: "https://cookwell.onrender.com",
    github: "https://github.com/manastelavane/RecipeNew",
  },
  {
    id: 2,
    category: "MERN",
    image:
      "https://raw.githubusercontent.com/manastelavane/RecipeImages/main/ecommerce.png",
    title: "Ecommerce Website (MERN stack)",
    desc: "This is an ecommerce website for selling products online.It includes features such as a shopping cart, product pages, search bar, customer reviews, filters, Mobile responsive, payment gateway, order tracking and Admin Panel made using Graphql .",
    demo: "https://ecommerce-manas.onrender.com/",
    github: "https://github.com/manastelavane/Ecommerce",
  },
  {
    id: 3,
    category: "Python",
    image:
      "https://raw.githubusercontent.com/manastelavane/RecipeImages/main/jarvis.png",
    title: "Jarvis-Audio Desktop Assistance (Python)",
    desc: "Welcome to Jarvis, an automated audio assistance tool that can help you with a variety of tasks. Whether you need help with scheduling, get information and check weather, read books, or just someone to talk to, Jarvis is here to help.",
    demo: "https://github.com/manastelavane/Jarvis--Audio-Desktop-Assistance-Software",
    github:
      "https://github.com/manastelavane/Jarvis--Audio-Desktop-Assistance-Software",
  },
];

export default data;
