import { FaMedal } from "react-icons/fa";
import Card from "../../components/Card";
import data from "./data";
import "./achievements.css";

const Achievements = () => {
  return (
    <section id="achievements">
      <h2>Achievements</h2>
      <p>Constantly striving for growth and improvement.</p>
      <div className="container achievements__container" data-aos="fade-up">
        {data.map((item) => (
          <Card key={item.id} className="achievement light">
            <div className="achievement__icon">
              <FaMedal />
            </div>
            <div className="achievement__details">
              <a href={item?.link} target="_blank" rel="noopener noreferrer">
                <h5>{item.title}</h5>
              </a>
              <div className="achievement__rank">
                <i>{item?.rank}</i>
              </div>
              <p>{item.desc}</p>
            </div>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default Achievements;
